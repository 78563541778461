.home > .features {
  padding: 58px 20px 0px;
  display: flex;
  flex-direction: column;
}

/* desc  */
.home > .features > .desc {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  word-wrap: break-word;
  margin-top: 20px;
}
.home > .features > .desc > pre {
  color: #fff;
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  word-wrap: break-word;
  margin: -10px 0 0 0;
}

/* features collection */
.home > .features > .featurescollection {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 30px;
}
.home > .features > .featurescollection > div {
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: center;
}

/* primary feature box */
.home > .features > .featurescollection > div > .primaryfeaturebox,
.home > .mobileFeatures > .features > .primaryfeaturebox,
.home > .features > .featurescollection > div > .secondaryfeaturebox {
  padding: 5px;
  border-radius: 10px;
  width: 345px;
  transition: all 0.75s;
}
.home > .features > .featurescollection > div > .primaryfeaturebox > .container,
.home > .mobileFeatures > .features > .primaryfeaturebox > .container,
.home > .features > .featurescollection > div > .secondaryfeaturebox > .container {
  border-radius: 10px;
  background-color: #021e14;
  padding: 35px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  text-align: center;
  cursor: pointer;
}
.home > .features > .featurescollection > div > .primaryfeaturebox > .container > img,
.home > .mobileFeatures > .features > .primaryfeaturebox > .container > img {
  height: 40px;
}
.home > .features > .featurescollection > div > .primaryfeaturebox > .container > .header,
.home > .mobileFeatures > .features > .primaryfeaturebox > .container > .header,
.home > .features > .featurescollection > div > .secondaryfeaturebox > .container > .header {
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  word-wrap: break-word;
}
.home > .features > .featurescollection > div > .primaryfeaturebox > .container > .desc,
.home > .mobileFeatures > .features > .primaryfeaturebox > .container > .desc,
.home > .features > .featurescollection > div > .secondaryfeaturebox > .container > .desc {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  word-wrap: break-word;
}

/* seconday feature box */
.home > .features > .featurescollection > div > .secondaryfeaturebox {
  padding: 3px;
}

/* color  */
.home > .features > .featurescollection > div > .pink,
.home > .mobileFeatures > .features > .pink {
  background: linear-gradient(to bottom, #f522edd9, #fff);
}
.home > .features > .featurescollection > div > .pink:hover,
.home > .mobileFeatures > .features > .pink:hover {
  box-shadow: 0px 0px 28px 0px #f522edd9;
}
.home > .features > .featurescollection > div > .green,
.home > .mobileFeatures > .features > .green {
  background: linear-gradient(to bottom, #2ed148d9, #fff);
}
.home > .features > .featurescollection > div > .green:hover,
.home > .mobileFeatures > .features > .green:hover {
  box-shadow: 0px 0px 28px 0px #2ed148d9;
}
.home > .features > .featurescollection > div > .blue,
.home > .mobileFeatures > .features > .blue {
  background: linear-gradient(to bottom, #1890ffd9, #fff);
}
.home > .features > .featurescollection > div > .blue:hover,
.home > .mobileFeatures > .features > .blue:hover {
  box-shadow: 0px 0px 28px 0px #1890ffd9;
}

.home > .mobileFeatures {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.home > .mobileFeatures > .features {
  display: flex;
  flex-direction: column;
  gap: 22px;
}
.home > .mobileFeatures > .subFeatures {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  margin-top: 20px;
  background-color: #d9d9d90d;
  width: 345px;
  border: 0.5px solid #ffffff8c;
}
.home > .mobileFeatures > .subFeatures > div {
  border-bottom: 0.5px solid #ffffff8c;
  padding: 10px 0 10px 20px;
  font-size: 13px;
  font-weight: 600;
  color: white;
}
.home > .mobileFeatures > .ai {
  display: flex;
  align-items: center;
  width: 345px;
  align-self: center;
  justify-content: center;
}
.home > .mobileFeatures > .ai > div {
  width: 213px;
  font-weight: 700;
  font-size: 25px;
  background: linear-gradient(0deg, #2ed148 0%, #ffffff 81.99%), #ffd100;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.home > .mobileFeatures > .ai > img {
  width: 130px;
}

@media only screen and (max-width: 400px) {
  .home > .mobileFeatures > .subFeatures {
    width: 90vw;
  }
  .home > .mobileFeatures > .ai {
    width: 90vw;
  }
  .home > .mobileFeatures > .ai > div {
    width: fit-content;
    font-size: 15.5px;
  }
  .home > .mobileFeatures > .ai > img {
    width: 80px;
  }
  .home > .mobileFeatures > .features > .primaryfeaturebox > .container > .header,
  .home > .mobileFeatures > .features > .primaryfeaturebox > .container > .desc {
    font-size: 14px;
  }
  .home > .mobileFeatures > .features > .primaryfeaturebox {
    width: 90vw;
  }
  .home > .mobileFeatures > .features > .primaryfeaturebox > .container {
    padding: 20px 8px;
  }
}

@media only screen and (max-width: 1000px) {
  .home > .features > .desc {
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    word-wrap: break-word;
    margin-top: 20px;
  }
  .home > .features > .desc > pre {
    color: #fff;
    font-size: 22px;
    font-weight: 700;
    line-height: 40px;
    word-wrap: break-word;
    margin: -10px 0 0 0;
  }
  .home > .features > .featurescollection > div > .primaryfeaturebox > .container > .header,
  .home > .features > .featurescollection > div > .secondaryfeaturebox > .container > .header,
  .home > .features > .featurescollection > div > .primaryfeaturebox > .container > .desc,
  .home > .features > .featurescollection > div > .secondaryfeaturebox > .container > .desc {
    font-size: 13px;
  }
  .home > .features > .featurescollection > div > .primaryfeaturebox > .container,
  .home > .features > .featurescollection > div > .secondaryfeaturebox > .container {
    padding: 25px 10px;
  }
}
