.mission > .bottom {
  display: flex;
  gap: 55px;
  justify-content: center;
  padding: 61px 0;
  border-top: 1px solid white;
  align-items: center;
}
.mission > .bottom > div {
  color: #fff;
  font-size: 55px;
  font-weight: 700;
  line-height: 80px;
  width: 737px;
}
.mission > .bottom > .button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 385px;
  height: 91px;
  border-radius: 23px;
  border: 2px solid #2ed148;
  color: #fff;
  font-size: 22px;
  font-weight: 600;
  background: rgba(0, 0, 0, 0);
  text-decoration: none;
}

@media only screen and (max-width: 1250px) {
  .mission > .bottom {
    flex-direction: column;
  }
  .mission > .bottom > div {
    text-align: center;
  }
}
@media only screen and (max-width: 750px) {
  .mission > .bottom > div {
    width: fit-content;
    font-size: 36px;
    line-height: 52px;
  }
  .mission > .bottom > button {
    width: 350px;
    height: 80px;
    margin-bottom: 75px;
  }
}
@media only screen and (max-width: 450px) {
  .mission > .bottom {
    padding: 32px 0;
    gap: 28px;
  }
  .mission > .bottom > div {
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    width: 90vw;
  }
  .mission > .bottom > .button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 240px;
    height: 50px;
    border-radius: 23px;
    border: 2px solid #2ed148;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    background: rgba(0, 0, 0, 0);
    text-decoration: none;
  }
}
