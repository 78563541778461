.faq > .body > .faqs {
  display: flex;
  flex-direction: column;
  width: 1098px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.15) 0%, rgba(46, 209, 72, 0) 100%, rgba(14, 30, 17, 0) 100%);
  border-radius: 25px;
  padding: 0px 72px 0px 36px;
}
.faq > .body > .faqs > .individualFaq {
  display: flex;
  gap: 32px;
  padding: 42px 0;
  align-items: flex-start;
}
.faq > .body > .faqs > .individualFaq > img {
  cursor: pointer;
  height: 30px;
  transition: all 0.75s;
  margin-top: 5px;
}
.faq > .body > .faqs > .individualFaq > div {
  display: flex;
  flex-direction: column;
  width: 875px;
}
.faq > .body > .faqs > .individualFaq > div > .question {
  color: white;
  font-size: 30px;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  cursor: pointer;
}
.faq > .body > .faqs > .individualFaq > div > .answer {
  color: white;
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  line-height: 28px;
  transform-origin: top center;
  word-wrap: break-word;
  max-height: 0;
  transform: scaleY(0);
}

@keyframes expand {
  0% {
    transform: scaleY(0);
    max-height: 0;
  }

  100% {
    max-height: 56px;
    transform: scaleY(1);
    margin-top: 32px;
  }
}
@keyframes expand2 {
  0% {
    transform: scaleY(1);
    max-height: 56px;
    margin-top: 32px;
  }

  100% {
    transform: scaleY(0);
    max-height: 0px;
    margin-top: 0px;
  }
}

@media only screen and (max-width: 1150px) {
  .faq > .body > .faqs {
    width: 90vw;
    padding: 0px 0 0px 5px;
    border-radius: 17px;
  }
  .faq > .body > .faqs > .individualFaq {
    display: flex;
    gap: 2vw;
    padding: 22.8px 0 22.8px 14.3px;
    align-items: flex-start;
  }
  .faq > .body > .faqs > .individualFaq > img {
    height: 25px;
  }
  .faq > .body > .faqs > .individualFaq > div {
    display: flex;
    flex-direction: column;
    width: 93%;
  }
  @keyframes expand {
    0% {
      transform: scaleY(0);
      max-height: 0;
    }

    100% {
      max-height: 200px;
      transform: scaleY(1);
      margin-top: 16px;
    }
  }
  @keyframes expand2 {
    0% {
      transform: scaleY(1);
      max-height: 200px;
      margin-top: 16px;
    }

    100% {
      transform: scaleY(0);
      max-height: 0px;
      margin-top: 0px;
    }
  }
}

@media only screen and (max-width: 650px) {
  .faq > .body > .faqs > .individualFaq > img {
    height: 15px;
    margin-top: 3.5px;
  }
  .faq > .body > .faqs > .individualFaq > div > .question {
    font-size: 17px;
  }
  .faq > .body > .faqs > .individualFaq > div > .answer {
    color: white;
    font-size: 12px;
    line-height: 16px;
  }
  @keyframes expand {
    0% {
      transform: scaleY(0);
      max-height: 0;
    }

    100% {
      max-height: 120px;
      transform: scaleY(1);
      margin-top: 16px;
    }
  }
  @keyframes expand2 {
    0% {
      transform: scaleY(1);
      max-height: 120px;
      margin-top: 16px;
    }

    100% {
      transform: scaleY(0);
      max-height: 0px;
      margin-top: 0px;
    }
  }
}
