.freeofferpopup {
  scale: 0.75;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(calc(-50% * 4 / 3), calc(-50% * 4 / 3));
  background: url("../../../Assets/FreeOfferPopup/background.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 519px;
  width: 977px;
  padding: 24px 24px 24px 451px;
  border-radius: 40px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 100px 0px #2ed148;
  animation: popup 0.5s;
}
.freeofferpopup > .close {
  align-self: flex-end;
  margin-bottom: 13px;
}
.freeofferpopup > .close > img {
  height: 45px;
  width: 45px;
}
.freeofferpopup > .header {
  color: #2ed148;
  font-size: 48px;
  font-weight: 700;
  word-wrap: break-word;
  margin-bottom: 12px;
  font-family: "Inter", sans-serif;
}
.freeofferpopup > .desc {
  color: white;
  font-size: 20px;
  font-weight: 400;
  word-wrap: break-word;
  font-family: "Inter", sans-serif;
  width: 485px;
  line-height: 24px;
  margin-bottom: 32px;
}
.freeofferpopup > .desc > span {
  font-weight: 700;
}
.freeofferpopup > .input {
  display: flex;
  border: 1.28px #2ed148 solid;
  border-radius: 12.78px;
  height: 55px;
  width: 470px;
  align-items: center;
  margin-bottom: 16px;
  background-color: black;
}
.freeofferpopup > .input > input {
  outline: 0;
  border: 0;
  color: white;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  line-height: 10.22px;
  letter-spacing: 0.28px;
  word-wrap: break-word;
  margin: 0 25.5px;
  width: 285px;
  background-color: black;
}
.freeofferpopup > .input > input::placeholder {
  color: white;
}
.freeofferpopup > .input > div {
  background-color: #2ed148;
  height: 55px;
  width: 133px;
  border-top-right-radius: 12.78px;
  border-bottom-right-radius: 12.78px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 15px;
  font-family: "Inter" sans-serif;
  font-weight: 700;
  letter-spacing: 0.28px;
  cursor: pointer;
}
.freeofferpopup > .learnmore {
  color: #2ed148;
  font-size: 16px;
  font-family: "Inter" sans-serif;
  font-weight: 400;
  text-decoration: underline;
  margin-bottom: 19px;
}
.freeofferpopup > .status {
  height: 40px;
  font-size: 18px;
  font-weight: 700;
  display: flex;
  text-align: center;
  align-self: flex-start;
}
.freeofferpopup > .discord {
  display: flex;
  width: 416px;
  flex-direction: column;
  gap: 12px;
  align-items: center;
}
.freeofferpopup > .discord > div {
  color: #fff;
  font-size: 15.3px;
  font-family: "Inter" sans-serif;
  font-weight: 400;
}
.freeofferpopup > .discord > .link > img {
  width: 226px;
  height: 43px;
}

@keyframes popup {
  0% {
    top: 100%;
    left: 100%;
    scale: 0.3;
    transform: translate(calc(-50% * 10 / 3), calc(-50% * 10 / 3));
  }
  100% {
    top: 50%;
    left: 50%;
    scale: 0.75;
    transform: translate(calc(-50% * 100 / 75), calc(-50% * 100 / 75));
  }
}

@keyframes popupA {
  0% {
    top: 100%;
    left: 100%;
    scale: 0.3;
    transform: translate(calc(-50% * 10 / 3), calc(-50% * 10 / 3));
  }
  100% {
    top: 50%;
    left: 50%;
    scale: 0.6;
    transform: translate(calc(-50% * 5 / 3), calc(-50% * 5 / 3));
  }
}

@media only screen and (max-width: 900px) {
  .freeofferpopup {
    scale: 0.6;
    top: 50%;
    left: 50%;
    transform: translate(calc(-50% * 5 / 3), calc(-50% * 5 / 3));
    animation: popupA 0.5s;
  }
}
@media only screen and (max-width: 675px) {
  .freeofferpopup {
    height: 659px;
    width: calc(95vw * 1.6);
    background: #021e14;
    padding: 32px;
    align-items: center;
  }
  .freeofferpopup > .close {
    margin-bottom: 4px;
    scale: 1.5;
  }
  .freeofferpopup > .header {
    color: #2ed148;
    font-size: 64px;
    font-weight: 700;
    word-wrap: break-word;
    margin-bottom: 12px;
    font-family: "Inter", sans-serif;
  }
  .freeofferpopup > .desc {
    font-size: 28px;
    width: 85%;
    text-align: center;
    line-height: 32px;
    margin-bottom: 64px;
  }
  .freeofferpopup > .input {
    display: flex;
    border: 1.28px #2ed148 solid;
    border-radius: 12.78px;
    height: 55px;
    width: 470px;
    align-items: center;
    margin-bottom: 26px;
    background-color: black;
    scale: 1.25;
  }
  .freeofferpopup > .learnmore {
    font-size: 24px;
  }
  .freeofferpopup > .discord {
    scale: 1.5;
  }
}
@media only screen and (max-width: 425px) {
  .freeofferpopup {
    width: calc(99vw * 1.6);
  }
  .freeofferpopup > .discord {
    scale: 1.15;
  }
  .freeofferpopup > .input {
    scale: 1.15;
  }
}
