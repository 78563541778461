.blog > .body > .blogs {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 28px;
  margin-bottom: 40px;
}
.blog > .body > .blogs > .individualBlog {
  border: 0.5px solid #2ed148;
  background: rgba(217, 217, 217, 0.15);
  border-radius: 25px;
  width: 435px;
  height: 435px;
  padding: 12px 13px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.blog > .body > .blogs > .individualBlog > div > .top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6px;
}
.blog > .body > .blogs > .individualBlog > div > .top > .date {
  color: rgb(255, 255, 255, 0.55);
  font-size: 14px;
  font-weight: 700;
}
.blog > .body > .blogs > .individualBlog > div > .top > img {
  height: 45px;
  width: 45px;
  border-radius: 50%;
}
.blog > .body > .blogs > .individualBlog > div > .title {
  color: white;
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 17px;
}
.blog > .body > .blogs > .individualBlog > div > .desc {
  color: #ffffffbf;
  font-size: 17px;
  font-weight: 600;
}
.blog > .body > .blogs > .individualBlog > button {
  width: 407px;
  height: 45px;
  align-self: center;
  border-radius: 10px;
  border: 0;
  outline: 0;
  background: #021e148c;
  color: #ffffff80;
  font-size: 17px;
  font-weight: 600;
  transition: all 0.5s;
  cursor: pointer;
}
.blog > .body > .blogs > .individualBlog > button:hover {
  background: #021e14;
  color: #ffffff;
}

@media only screen and (max-width: 1000px) {
  .blog > .body > .blogs {
    justify-content: center;
    gap: 22px;
  }
}
@media only screen and (max-width: 500px) {
  .blog > .body > .blogs > .individualBlog {
    width: 348px;
  }
  .blog > .body > .blogs > .individualBlog > div > .title {
    font-size: 20px;
  }
  .blog > .body > .blogs > .individualBlog > div > .desc {
    font-size: 15px;
  }
  .blog > .body > .blogs > .individualBlog > button {
    width: 300px;
  }
}
