.home > .top {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.home > .top > .shading {
  width: 613.073px;
  height: 483.588px;
  border-radius: 613.073px;
  background: rgba(46, 209, 72, 0.1);
  filter: blur(50px);
  position: absolute;
  top: 64px;
  z-index: -1;
}
.home > .top > .head {
  margin-top: 80px;
  font-size: 60px;
  color: #fff;
  font-weight: 700;
  width: 900px;
  text-align: center;
}
.home > .top > img {
  width: 300px;
  height: 11px;
  margin-bottom: 40px;
}
.home > .top > .desc {
  font-size: 23px;
  color: #2ed148;
  font-weight: 700;
  text-align: center;
}
.home > .top > .freeoffer {
  width: 359px;
  height: 75px;
  background: #2ed148;
  border-radius: 23px;
  font-weight: 600;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  text-decoration: none;
  margin-top: 66px;
  margin-bottom: 18px;
}
.home > .top > .freeofferdesc {
  color: #2ed148;
  font-weight: 600;
  font-size: 10px;
  text-align: center;
  width: 184px;
}

.home > .mobileTop {
  margin-top: 50px;
  margin-left: 22px;
  display: flex;
  flex-direction: column;
}
.home > .mobileTop > .prehead {
  font-weight: 700;
  font-size: 14px;
  background: linear-gradient(0deg, #2ed148 0%, #ffffff 81.99%), #ffd100;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin-bottom: 14px;
}
.home > .mobileTop > .head {
  width: calc(90vw - 22px);
  font-size: 36px;
  font-weight: 700;
  color: white;
  margin-bottom: 20px;
}
.home > .mobileTop > .desc {
  width: calc(90vw - 22px);
  font-size: 14px;
  font-weight: 500;
  color: #dde2ff;
  margin-bottom: 35px;
}
.home > .mobileTop > .freeoffer {
  width: 147px;
  height: 48px;
  background: #22a75d;
  box-shadow: 0px 15px 25px rgba(34, 167, 93, 0.25);
  border-radius: 52px;
  font-weight: 600;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  margin-bottom: 35px;
  text-decoration: none;
}
.home > .mobileTop > .subhead {
  font-size: 32px;
  color: white;
  font-weight: 700;
}
.home > .mobileTop > img {
  width: 100vw;
  align-self: center;
  display: none;
  margin-top: -40px;
  margin-bottom: -40px;
}

@media only screen and (max-width: 1000px) {
  .home > .top > .head {
    margin-top: 80px;
    font-size: 40px;
    color: #fff;
    font-weight: 700;
    width: 600px;
    text-align: center;
  }
  .home > .top > .desc {
    font-size: 18px;
    color: #2ed148;
    font-weight: 700;
    text-align: center;
  }
}
