.home > .testimonial {
  background: rgba(46, 209, 73, 0.1175);
  transform: rotateZ(-3deg);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 216px 0 50px 0;
  margin: 7vh -3vw;
}
.home > .testimonial > .top {
  transform: rotateZ(3deg);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 960px;
  gap: 32px;
  padding-bottom: 32px;
  border-bottom: 0.5px solid #dec9fa;
}
.home > .testimonial > .top > .quote {
  color: #269638;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  word-wrap: break-word;
  text-align: center;
}
.home > .testimonial > .top > img {
  width: 60px;
}
.home > .testimonial > .top > .info {
  color: #fff;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  word-wrap: break-word;
  text-align: center;
}
.home > .testimonial > img {
  transform: rotateZ(3deg);
  height: 79px;
  margin-top: 36px;
}

.home > .mobileTestimonial {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  border-bottom: 1px solid #dec9fa;
  margin-bottom: 80px;
}
.home > .mobileTestimonial > .top {
  font-size: 24px;
  font-weight: 500;
  color: #269638;
  text-align: center;
}
.home > .mobileTestimonial > img {
  margin: 32px 0;
  width: 60.16px;
  height: 53.41px;
}
.home > .mobileTestimonial > .info {
  font-size: 24px;
  font-weight: 500;
  color: white;
  text-align: center;
  margin-bottom: 32px;
}

@media only screen and (max-width: 1000px) {
  .home > .testimonial > .top {
    width: 700px;
  }
  .home > .testimonial {
    padding: 100px 0 50px 0;
  }
}
