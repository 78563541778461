.mission > .body {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 127px;
}
.mission > .body > .keypoints {
  display: flex;
  gap: 90px;
}
.mission > .body > .keypoints > div {
  display: flex;
  gap: 20px;
}
.mission > .body > .keypoints > div > img {
  width: 48.6px;
  height: 48.6px;
  margin-top: 13px;
}
.mission > .body > .keypoints > div > div {
  display: flex;
  gap: 0.67px;
  flex-direction: column;
}
.mission > .body > .keypoints > div > div > .head {
  color: #fff;
  font-size: 28px;
  font-weight: 700;
}
.mission > .body > .keypoints > div > div > .desc {
  color: rgb(255, 255, 255, 0.75);
  font-size: 12px;
  font-weight: 700;
  width: 270px;
}

.mission > .body > img {
  width: 554px;
  height: 450px;
  margin-top: 33px;
}

.mission > .body > .bottom {
  margin-top: 28px;
  display: flex;
  gap: 76px;
  margin-bottom: 54px;
}
.mission > .body > .bottom > .left {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.mission > .body > .bottom > .left > .head {
  color: #2ed148;
  font-family: Familjen Grotesk;
  font-size: 25px;
  font-weight: 700;
}
.mission > .body > .bottom > .left > .desc {
  color: #fff;
  font-size: 35px;
  font-weight: 700;
  width: 593px;
  line-height: 40px;
}
.mission > .body > .bottom > .right {
  display: flex;
  flex-direction: column;
  gap: 22px;
}
.mission > .body > .bottom > .right > div {
  display: flex;
  gap: 28.42px;
  align-items: center;
}
.mission > .body > .bottom > .right > div > img {
  width: 42px;
  height: 42px;
}
.mission > .body > .bottom > .right > div > div {
  color: #fff;
  font-size: 20px;
  font-weight: 500;
}

@media only screen and (max-width: 1250px) {
  .mission > .body > .keypoints {
    display: flex;
    gap: 30px;
    flex-direction: column;
  }
  .mission > .body > .bottom {
    flex-direction: column;
    align-items: center;
  }
  .mission > .body > .bottom > .left {
    align-items: center;
  }
  .mission > .body > .bottom > .left > .desc {
    text-align: center;
  }
}

@media only screen and (max-width: 600px) {
  .mission > .body > .bottom > .left > .desc {
    font-size: 23px;
    width: fit-content;
  }
  .mission > .body > .bottom > .right {
    display: flex;
    flex-direction: column;
    gap: 22px;
    margin-left: 10px;
  }
  .mission > .body > img {
    scale: 0.85;
  }
}
@media only screen and (max-width: 400px) {
  .mission > .body {
    margin-top: 60px;
  }
  .mission > .body > .keypoints {
    scale: 0.8;
  }
  .mission > .body > .bottom {
    scale: 0.8;
    margin-top: 0px;
    margin-bottom: 10px;
  }
  .mission > .body > img {
    scale: 0.75;
    margin-top: 0;
  }
}
