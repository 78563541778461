.nav {
  display: flex;
  justify-content: space-between;
  padding: 10px 8px 10px 10vw;
  align-items: center;
  position: sticky;
  top: 0;
  background: rgba(255, 255, 255, 0);
  z-index: 1;
}
.nav > div > .navitem {
  text-decoration: none;
  color: #ffffffa6;
  font-size: 18px;
  font-weight: 500;
  word-wrap: break-word;
  transition: color 0.5s;
  background-color: #00000000;
  border: 0;
  outline: 0;
  cursor: pointer;
}
.nav > div > .navitem:hover {
  color: #fff;
}
.nav > div > .activeItem {
  color: #2ed148;
}
.nav > .left > .brandname {
  color: #2ed148;
  font-size: 20px;
}
.nav > .right {
  display: flex;
  gap: 40px;
  margin-right: 30px;
  align-items: center;
}
.nav > .right > .freeoffer {
  padding: 12px 24px;
  border: 0.5px #2ed148 solid;
  border-radius: 23px;
  transition: all 0.5s;
  color: white;
}
.nav > .right > .aboutPopup {
  position: absolute;
  top: 56px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 13px 10px;
  background-color: #021e14;
  border-radius: 15px;
  margin-left: -58px;
  /* border: 1px solid white; */
}
.nav > .right > .aboutPopup > .aboutItem {
  display: flex;
  gap: 8px;
  width: 160px;
  border-radius: 8px;
  color: white;
  align-items: center;
  text-decoration-line: none;
  padding: 0px 0px 0px 8px;
}
.nav > .right > .aboutPopup > .active {
  background-color: rgba(46, 209, 72, 0.2);
  color: #2ed148;
}
.nav > .right > .aboutPopup > .security {
  gap: 15px;
  flex-direction: row;
}
.nav > .right > .aboutPopup > .mission {
  gap: 13px;
  flex-direction: row;
}
.nav > .right > .aboutPopup > .aboutItem > img {
  width: fit-content;
  height: fit-content;
}
.nav > .right > .aboutPopup > .aboutItem > div {
  font-weight: 500;
  font-size: 15px;
  margin: 7px 0;
}

.mobileNav {
  display: flex;
  justify-content: space-between;
  padding: 50px 20px 0;
  align-items: center;
}
.mobileNav > .left {
  display: flex;
  gap: 5px;
  align-items: center;
  text-decoration: none;
  color: white;
}
.mobileNav > .left > img {
  width: 42.22px;
  height: 39.38px;
}
.mobileNav > .left > div {
  font-size: 27px;
  font-weight: 600;
}
.mobileNav > .right > img {
  width: 24px;
  height: 24px;
}

@media only screen and (max-width: 1000px) {
  .nav > div > .navitem {
    font-size: 12px;
  }
  .nav > .left > .brandname {
    color: #2ed148;
    font-size: 14px;
  }
  .nav > .right > .freeoffer {
    padding: 9px 18px;
    border: 0.5px #2ed148 solid;
    border-radius: 23px;
    transition: all 0.5s;
    color: white;
  }
}

.mobileNav > .popup {
  position: absolute;
  width: 100vw;
  background-color: #021e14;
  border: 1px solid white;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  z-index: 2;
}
.mobileNav > .popup > .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px 0;
}
.mobileNav > .popup > .top > .img {
  height: 27px;
  width: 27px;
}
.mobileNav > .popup > .top > .left {
  display: flex;
  gap: 5px;
  align-items: center;
  text-decoration: none;
  color: white;
  align-self: center;
}
.mobileNav > .popup > .top > .left > img {
  width: 42.22px;
  height: 39.38px;
}
.mobileNav > .popup > .top > .left > div {
  font-size: 27px;
  font-weight: 600;
}
.mobileNav > .popup > .links {
  display: flex;
  flex-direction: column;
  padding: 28px 0 0 28px;
  gap: 15px;
  align-items: flex-start;
}

.mobileNav > .popup > .links > .navitem {
  text-decoration: none;
  color: #ffffffa6;
  font-size: 18px;
  font-weight: 500;
  word-wrap: break-word;
  transition: color 0.5s;
  background-color: #00000000;
  border: 0;
  outline: 0;
  cursor: pointer;
}
.mobileNav > .popup > .links > .navitem:hover {
  color: #fff;
}
.mobileNav > .popup > .links > .activeItem {
  color: #2ed148;
}
.mobileNav > .popup > .freeoffer {
  text-decoration: none;
  color: #ffffffa6;
  font-size: 22px;
  font-weight: 500;
  word-wrap: break-word;
  transition: color 0.5s;
  background-color: #00000000;
  border: 0;
  outline: 0;
  cursor: pointer;
  padding: 12px 10px;
  border: 0.5px #2ed148 solid;
  border-radius: 23px;
  transition: all 0.5s;
  color: white;
  align-self: center;
  margin-top: 150px;
}
.mobileNav > .popup > .credit {
  align-self: center;
  margin-top: 15px;
  font-weight: 600;
  font-size: 17px;
  line-height: 26px;
  text-decoration-line: underline;
  color: rgba(255, 255, 255, 0.75);
}
.mobileNav > .popup > img {
  align-self: center;
  width: 42px;
  height: 39px;
  margin: 0 0 36px;
}

.mobileNav > .popup > .aboutPopup {
  position: absolute;
  top: 230px;
  left: 50px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 13px 10px;
  background-color: #021e14;
  border-radius: 15px;
  z-index: -1;
}
.mobileNav > .popup > .aboutPopup > .aboutItem {
  display: flex;
  gap: 8px;
  width: 160px;
  border-radius: 8px;
  color: white;
  align-items: center;
  text-decoration-line: none;
  padding: 0px 0px 0px 8px;
}
.mobileNav > .popup > .aboutPopup > .active {
  background-color: rgba(46, 209, 72, 0.2);
  color: #2ed148;
}
.mobileNav > .popup > .aboutPopup > .security {
  gap: 15px;
  flex-direction: row;
}
.mobileNav > .popup > .aboutPopup > .mission {
  gap: 13px;
  flex-direction: row;
}
.mobileNav > .popup > .aboutPopup > .aboutItem > img {
  width: fit-content;
  height: fit-content;
}
.mobileNav > .popup > .aboutPopup > .aboutItem > div {
  font-weight: 500;
  font-size: 15px;
  margin: 7px 0;
}
