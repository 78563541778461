.security {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.security > .title {
  margin-top: 560px;
  color: #fff;
  text-align: center;
  font-size: 75px;
  font-weight: 700;
}

.security > .desc {
  margin-top: 35px;
  color: #fff;
  text-align: center;
  font-size: 25px;
  font-weight: 400;
  width: 539px;
}

.security > .button {
  margin-top: 50px;
  display: flex;
  width: 313px;
  height: 74px;
  justify-content: center;
  align-items: center;
  border-radius: 23px;
  background: #fff;
  color: #000;
  font-size: 22px;
  font-weight: 600;
  cursor: pointer;
}

.security > .details {
  display: flex;
  gap: 72.31px;
  margin-top: 76px;
}
.security > .details > div {
  width: 270.76px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.security > .details > div > img {
  width: 48.656px;
  height: 48.656px;
  margin-bottom: 5px;
}
.security > .details > div > .title {
  color: #fff;
  text-align: center;
  font-size: 28px;
  font-weight: 700;
}
.security > .details > div > .desc {
  color: rgba(255, 255, 255, 0.75);
  text-align: center;
  font-size: 12px;
  font-weight: 700;
}

.security > .securityHead {
  margin-top: 135px;
  margin-bottom: 49px;
  color: #fff;
  text-align: center;
  font-size: 45px;
  font-weight: 700;
}

.security > .securityDetail {
  display: flex;
  margin-bottom: 90px;
}
.security > .securityDetail > div {
  background: #021e14;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 383.306px;
  height: 348.315px;
  border-radius: 25px;
  border: 1px solid #fff;
  background: #021e14;
  padding-top: 10px;
  margin-top: 35.68px;
}
.security > .securityDetail > .boxA {
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.security > .securityDetail > .boxB {
  height: 446.886px;
  margin-top: 0;
}
.security > .securityDetail > .boxC {
  border-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.security > .securityDetail > div > img {
  width: 144px;
  height: 144px;
}
.security > .securityDetail > div > .title {
  color: #fff;
  text-align: center;
  font-size: 45px;
  font-weight: 700;
  margin-top: 20px;
}
.security > .securityDetail > .boxB > .title {
  margin-top: 34px;
  margin-bottom: 26px;
}
.security > .securityDetail > div > .desc {
  width: 270.76px;
  color: rgba(255, 255, 255, 0.75);
  text-align: center;
  font-size: 12px;
  font-weight: 700;
}

.security > .bottom {
  padding: 58px 0;
  display: flex;
  justify-content: center;
  border-top: 1px solid white;
  width: 100%;
}
.security > .bottom > .bottomCont {
  display: flex;
  justify-content: space-between;
  border-radius: 15px;
  border: 0.5px solid #fff;
  width: 1177px;
  padding: 20px 22px;
  align-items: center;
}
.security > .bottom > .bottomCont > .left {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.security > .bottom > .bottomCont > .left > .title {
  color: #fff;
  font-size: 45px;
  font-weight: 700;
}
.security > .bottom > .bottomCont > .left > .desc {
  color: rgba(255, 255, 255, 0.75);
  font-size: 20px;
  font-weight: 700;
}
.security > .bottom > .bottomCont > .button {
  display: flex;
  width: 182px;
  height: 74px;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  background: #fff;
  color: #000;
  font-size: 22px;
  font-weight: 600;
  cursor: pointer;
}

.security > .shield {
  width: 832.588px;
  height: 696.061px;
  position: absolute;
  top: 117.27px;
  z-index: -1;
}
.security > .lines {
  position: absolute;
  top: 130.56px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 11.33px;
  z-index: -2;
}
.security > .lines > .lineA {
  width: 100%;
  height: 4.226px;
  background: linear-gradient(180deg, rgba(46, 209, 72, 0.01) 0%, rgba(46, 209, 72, 0) 100%);
}
.security > .lines > .lineB {
  width: 100%;
  height: 4.226px;
  background: linear-gradient(180deg, rgba(46, 209, 72, 0.02) 0%, rgba(46, 209, 72, 0) 100%);
}

@media only screen and (max-width: 1200px) {
  .security > .details {
    flex-direction: column;
  }
  .security > .securityDetail {
    flex-direction: column;
    gap: 11.37px;
  }
  .security > .securityDetail > div {
    width: 335.48px;
    height: 384.67px;
    padding-top: 10px;
    margin-top: 0px;
  }
  .security > .securityDetail > .boxA {
    border: 1px solid #fff;
    border-radius: 25px;
  }
  .security > .securityDetail > .boxB {
    height: 384.67px;
  }
  .security > .securityDetail > .boxC {
    border: 1px solid #fff;
    border-radius: 25px;
  }
  .security > .securityDetail > div > .title {
    font-size: 38px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .security > .securityDetail > .boxB > .title {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .security > .securityDetail > div > .desc {
    width: 240.76px;
    font-size: 12px;
  }
  .security > .bottom > .bottomCont {
    width: 90vw;
  }
  .security > .bottom > .bottomCont > .left {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .security > .bottom > .bottomCont > .left > .title {
    font-size: 32px;
  }
  .security > .bottom > .bottomCont > .left > .desc {
    font-size: 14px;
  }
  .security > .bottom > .bottomCont > .button {
    width: 140px;
    height: 40px;
    border-radius: 8px;
    font-size: 16px;
  }
}

@media only screen and (max-width: 750px) {
  .security > .title {
    margin-top: 400px;
    font-size: 36px;
  }

  .security > .desc {
    margin-top: 15px;
    color: #fff;
    text-align: center;
    font-size: 15px;
    font-weight: 200;
    width: 308px;
  }
  .security > .shield {
    width: 832.588px;
    height: 696.061px;
    position: absolute;
    top: -32px;
  }
  .security > .lines {
    display: none;
  }
  .security > .bottom {
    border-top: 0;
  }
  .security > .bottom > .bottomCont {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 95vw;
    border: white 0.5px solid;
  }
  .security > .bottom > .bottomCont > .left {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  .security > .bottom > .bottomCont > .left > .title {
    color: #fff;
    font-size: 36px;
    font-weight: 700;
  }
  .security > .bottom > .bottomCont > .left > .desc {
    font-size: 17px;
    text-align: center;
    margin-bottom: 30px;
  }
  .security > .bottom > .bottomCont > .button {
    width: 182px;
    height: 74px;
    border-radius: 15px;
    font-size: 22px;
    text-decoration: none;
  }
}

@media only screen and (max-width: 375px) {
  .security > .button {
    width: 240px;
    height: 55px;
    border-radius: 15px;
    font-size: 20px;
  }
  .security > .desc {
    width: 95vw;
  }
  .security > .details {
    gap: 60.31px;
  }
  .security > .details > div {
    width: 80vw;
  }
  .security > .securityHead {
    font-size: 35px;
  }
  .security > .securityDetail > div {
    width: 90vw;
    height: 395px;
    padding-top: 10px;
    margin-top: 0px;
  }
  .security > .securityDetail > div > .desc {
    width: 200px;
    color: rgba(255, 255, 255, 0.75);
    text-align: center;
    font-size: 11.5px;
    font-weight: 700;
  }
}
