.sendpopup {
  scale: 0.75;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(calc(-50% * 4 / 3), calc(-50% * 4 / 3));
  background: url("../../../Assets/SendPopup/background.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 519px;
  width: 977px;
  padding: 24px 24px 32px 250px;
  border-radius: 40px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 100px 0px #2ed148;
  animation: popup 0.5s;
}
.sendpopup > .close {
  align-self: flex-end;
}
.sendpopup > .close > img {
  height: 45px;
  width: 45px;
}
.sendpopup > .header {
  color: #fff;
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 11.69px;
  font-family: "Inter", sans-serif;
  margin-top: -20px;
}
.sendpopup > .desc {
  color: white;
  font-size: 18.5px;
  font-weight: 500;
  margin-bottom: 3.25px;
}
.sendpopup > textarea {
  height: 216px;
  width: 588px;
  font-size: 18.5px;
  padding: 5px 10px;
  resize: none;
  outline: 0;
  border-radius: 8px;
  border: 2px rgb(255, 255, 255) solid;
  margin-bottom: 26.64px;
  background: rgba(0, 0, 0, 0);
  color: white;
}
.sendpopup > .bottom {
  display: flex;
  gap: 90px;
  align-items: center;
}
.sendpopup > .bottom > .submitP {
  padding: 12.5px 0;
  width: 204px;
  border-radius: 8px;
  border: 2.5px solid rgba(0, 0, 0, 0.25);
  background: rgba(245, 245, 245, 0.07);
  color: rgba(255, 255, 255, 0.5);
  font-size: 19.5px;
  font-weight: 700;
  word-wrap: break-word;
  cursor: pointer;
}
.sendpopup > .bottom > .submitA {
  padding: 12.5px 0;
  width: 204px;
  border-radius: 8px;
  border: 2.5px solid #2ed148;
  background: rgba(245, 245, 245, 0);
  color: white;
  font-size: 19.5px;
  font-weight: 700;
  word-wrap: break-word;
  cursor: pointer;
}
.sendpopup > .bottom > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 406px;
  gap: 3.25px;
}
.sendpopup > .bottom > div > div {
  color: #fff;
  font-size: 16px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
}
.sendpopup > .bottom > div > div > span {
  color: #2ed148;
  font-size: 16px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
}
.sendpopup > .bottom > div > .link > img {
  width: 226px;
  height: 43px;
}

@keyframes popupB {
  0% {
    top: 100%;
    left: 100%;
    scale: 0.3;
    transform: translate(calc(-50% * 10 / 3), calc(-50% * 10 / 3));
  }
  100% {
    top: 50%;
    left: 50%;
    scale: 0.5;
    transform: translate(calc(-50% * 2), calc(-50% * 2));
  }
}

@media only screen and (max-width: 900px) {
  .sendpopup {
    scale: 0.6;
    top: 50%;
    left: 50%;
    transform: translate(calc(-50% * 5 / 3), calc(-50% * 5 / 3));
    animation: popupA 0.5s;
  }
}

@media only screen and (max-width: 600px) {
  .sendpopup {
    scale: 0.5;
    top: 50%;
    left: 50%;
    transform: translate(calc(-50% * 2), calc(-50% * 2));
    animation: popupB 0.5s;
  }
}
@media only screen and (max-width: 500px) {
  .sendpopup {
    height: 680px;
    width: calc(95vw * 2);
    background: #021e14;
    align-items: center;
    padding: 24px;
  }
  .sendpopup > .header {
    margin-top: 30px;
  }
  .sendpopup > .bottom {
    display: flex;
    gap: 50px;
    align-items: center;
    flex-direction: column;
  }
}
