.faq > .body {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 180px 0px 0px 0px;
}
.faq > .body > .shading {
  width: 698.946px;
  height: 551.324px;
  border-radius: 698.946px;
  background: rgba(46, 209, 72, 0.04);
  filter: blur(50px);
  position: absolute;
  z-index: -1;
}
.faq > .body > .s1 {
  top: -103px;
  left: -170px;
}
.faq > .body > .s2 {
  top: 40px;
  left: 56px;
}
.faq > .body > .s3 {
  top: -143px;
  right: -283px;
}
.faq > .body > .top {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 51px;
}
.faq > .body > .top > .header {
  color: #fff;
  font-size: 45px;
  font-weight: 700;
  line-height: 40px;
  margin-bottom: 5px;
}
.faq > .body > .top > .desc {
  color: rgba(204, 204, 204, 0.75);
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  text-align: center;
}
.faq > .body > .bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 180px;
  margin-bottom: 100px;
  gap: 30px;
}
.faq > .body > .bottom > .header {
  color: white;
  font-size: 40px;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  line-height: 40px;
}
.faq > .body > .bottom > .button {
  padding: 12px 24px;
  border-radius: 45px;
  border: 2px #062d05 solid;
  background-color: #fff;
  color: black;
  font-size: 30px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  text-decoration: none;
}

@media only screen and (max-width: 650px) {
  .faq > .body > .top {
    margin-bottom: 34px;
  }
  .faq > .body > .top > .header {
    font-size: 36px;
    font-weight: 700;
    text-align: center;
    line-height: 54px;
  }
  .faq > .body > .top > .desc {
    font-size: 15px;
    line-height: 24px;
  }
  .faq > .body > .bottom {
    margin-top: 75px;
    margin-bottom: 200px;
    gap: 12px;
  }
  .faq > .body > .bottom > .header {
    font-size: 28px;
  }
  .faq > .body > .bottom > .button {
    padding: 15px 31px;
    font-size: 20px;
  }
}
