.tos {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 57px;
}
.tos > .title {
  color: #fff;
  text-align: center;
  font-size: 60px;
  font-weight: 700;
  line-height: normal;
}
.tos > .desc {
  color: rgb(204, 204, 204, 0.75);
  text-align: center;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  width: 757.841px;
  border-bottom: 1px solid rgba(217, 217, 217, 0.55);
  padding-bottom: 28px;
}
.tos > .texts {
  color: rgb(204, 204, 204, 0.75);
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  width: 757.841px;
  margin-top: 25px;
}
.tos > .updated {
  margin-top: 80px;
  color: rgb(255, 255, 255, 0.55);
  font-size: 25px;
  font-weight: 600;
  width: 757.841px;
  margin-bottom: 70px;
}

@media only screen and (max-width: 850px) {
  .tos > .title {
    font-size: 36px;
  }
  .tos > .desc {
    color: #cccccc;
    font-size: 15px;
    width: 99vw;
    border-bottom: 0px solid rgba(217, 217, 217, 0.55);
  }
  .tos > .texts {
    color: rgb(204, 204, 204, 0.75);
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    width: 90vw;
    margin-top: 36px;
  }
  .tos > .updated {
    margin-top: 60px;
    color: rgb(255, 255, 255, 0.55);
    font-size: 17px;
    width: 90vw;
  }
}
