.blog > .blogExpanded {
  display: flex;
  flex-direction: column;
  margin-top: 51px;
  width: 60vw;
  margin-left: 10vw;
}
.blog > .blogExpanded > .shading {
  width: 698.946px;
  height: 551.324px;
  border-radius: 698.946px;
  background: rgba(46, 209, 72, 0.04);
  filter: blur(50px);
  position: absolute;
  z-index: -1;
}
.blog > .blogExpanded > .back {
  display: flex;
  gap: 4px;
  align-items: center;
  cursor: pointer;
}
.blog > .blogExpanded > .back > img {
  width: 16.088px;
  height: 11.5px;
}
.blog > .blogExpanded > .back > div {
  color: rgba(255, 255, 255, 0.55);
  font-size: 16px;
  font-weight: 600;
}

.blog > .blogExpanded > .date {
  margin-top: 72px;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.55);
}

.blog > .blogExpanded > .title {
  margin-top: 48px;
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  color: white;
}

.blog > .blogExpanded > .posted {
  display: flex;
  flex-direction: column;
  gap: 11px;
  margin-top: 64px;
}
.blog > .blogExpanded > .posted > .title {
  color: rgba(255, 255, 255, 0.55);
  font-size: 14px;
  font-weight: 600;
}
.blog > .blogExpanded > .posted > .info {
  display: flex;
  gap: 9px;
  align-items: center;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.25);
  width: fit-content;
  padding: 7.33px 13.13px 7.33px 3.72px;
}
.blog > .blogExpanded > .posted > .info > img {
  width: 45.986px;
  height: 43.693px;
  border-radius: 9999px;
}
.blog > .blogExpanded > .posted > .info > div > .name {
  color: rgba(255, 255, 255);
  font-size: 14px;
  font-weight: 700;
}
.blog > .blogExpanded > .posted > .info > div > .username {
  color: rgba(255, 255, 255, 0.55);
  font-size: 11px;
  font-weight: 700;
}

.blog > .blogExpanded > .markdown {
  color: rgba(255, 255, 255, 0.55);
  margin-top: 24px;
  margin-bottom: 36px;
  padding-top: 45px;
  border-top: 1px solid #d9d9d98c;
  white-space: pre-wrap;
}

@media only screen and (max-width: 750px) {
  .blog > .blogExpanded {
    display: flex;
    flex-direction: column;
    margin-top: 51px;
    width: 90vw;
    margin-left: 5vw;
  }
}
