@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Familjen+Grotesk:wght@100;200;300;400;500;600;700;800;900&family=Russo+One&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Poppins;
}
html {
  overflow-y: scroll;
  overflow-x: auto;
  background: radial-gradient(circle at 50%, #6161615b, #021e14) #021e14;
  max-width: 100vw;
}
::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

@media only screen and (max-width: 750px) {
  html {
    background: #021e14;
  }
}
