.home > .details {
  display: flex;
  flex-direction: column;
  gap: 60px;
  margin-top: 135px;
}
.home > .details > div {
  display: flex;
  gap: 100px;
  align-items: center;
  justify-content: center;
}
.home > .details > div > img {
  height: 306px;
  width: 406px;
}
.home > .mobileDetails {
  display: flex;
  flex-direction: column;
}
.home > .details > div > .detail {
  width: 474px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}
.home > .mobileDetails > .detail {
  width: calc(100vw - 22px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 18px;
  margin-left: 22px;
  margin-top: 50px;
}
.home > .details > div > .detail > .icon,
.home > .mobileDetails > .detail > .icon {
  display: flex;
  gap: 18px;
  padding: 8px 16px;
  background: rgba(46, 209, 73, 0.25);
  border-radius: 20px;
}
.home > .details > div > .detail > .icon > img,
.home > .mobileDetails > .detail > .icon > img {
  height: 24px;
}
.home > .details > div > .detail > .icon > span,
.home > .mobileDetails > .detail > .icon > span {
  color: #2ed148;
  font-size: 16px;
  font-weight: 500;
  word-wrap: break-word;
}
.home > .details > div > .detail > .header,
.home > .mobileDetails > .detail > .header {
  color: #fff;
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  word-wrap: break-word;
  background: linear-gradient(180deg, #ffffff 0%, #b9ffb8 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.home > .mobileDetails > .detail > .header {
  font-size: 27px;
}
.home > .details > div > .detail > .desc,
.home > .mobileDetails > .detail > .desc {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  word-wrap: break-word;
}

@media only screen and (max-width: 1000px) {
  .home > .details > div > img {
    height: calc(306px * 0.7);
    width: calc(406px * 0.7);
  }
  .home > .details > div {
    gap: 50px;
    padding: 0 20px;
  }
  .home > .details > div > .detail > .header {
    font-size: 28px;
  }
  .home > .details > div > .detail > .desc {
    font-size: 14px;
  }
}
