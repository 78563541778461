.mission > .top {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 130px;
}
.mission > .top > .shading {
  width: 613.073px;
  height: 483.588px;
  border-radius: 613.073px;
  background: rgba(46, 209, 72, 0.1);
  filter: blur(50px);
  position: absolute;
  top: 64px;
  z-index: -1;
}
.mission > .top > .shading1 {
  width: 613.073px;
  height: 483.588px;
  border-radius: 613.073px;
  background: rgba(0, 8, 255, 0.04);
  filter: blur(50px);
  position: absolute;
  top: 64px;
  z-index: -1;
}
.mission > .top > .shading2 {
  width: 678.073px;
  height: 207.588px;
  border-radius: 613.073px;
  background: rgba(204, 0, 255, 0.06);
  filter: blur(50px);
  position: absolute;
  top: 64px;
  z-index: -1;
}
.mission > .top > .head {
  margin-bottom: 19px;
  color: #2ed148;
  font-family: Familjen Grotesk;
  font-size: 25px;
  font-weight: 700;
}
.mission > .top > .subHead {
  margin-bottom: 85px;
  color: #fff;
  font-size: 70px;
  font-weight: 700;
  width: 964px;
  text-align: center;
}
.mission > .top > .desc {
  color: #fff;
  font-size: 30px;
  font-weight: 400;
  width: 964px;
  text-align: center;
  width: 840px;
}
.mission > .top > .desc > span {
  font-weight: 700;
}

@media only screen and (max-width: 1000px) {
  .mission > .top > .head {
    margin-bottom: 19px;
    color: #2ed148;
    font-family: Familjen Grotesk;
    font-size: 25px;
    font-weight: 700;
  }
  .mission > .top > .subHead {
    font-size: 62px;
    width: fit-content;
    padding: 0px 10px;
  }
  .mission > .top > .desc {
    font-size: 27px;
    width: fit-content;
    padding: 0px 10px;
  }
}
@media only screen and (max-width: 750px) {
  .mission > .top > .subHead {
    font-size: 36px;
    width: fit-content;
    padding: 0px 10px;
    margin-bottom: 15px;
  }
  .mission > .top > .desc {
    font-size: 15px;
    width: fit-content;
    padding: 0px 10px;
    font-weight: 200;
  }
}
