.blog > .body {
  display: flex;
  flex-direction: column;
  padding: 92px 42px 0;
  align-items: flex-start;
}
.blog > .body > .shading {
  width: 698.946px;
  height: 551.324px;
  border-radius: 698.946px;
  background: rgba(46, 209, 72, 0.04);
  filter: blur(50px);
  position: absolute;
  z-index: -1;
}
.blog > .body > .top {
  color: #fff;
  font-size: 45px;
  font-weight: 700;
  margin-bottom: 12px;
}
.blog > .body > .search {
  display: flex;
  align-items: center;
  padding: 4px 8px;
  border-radius: 10px;
  background: #f2f2f240;
  margin-bottom: 24px;
}
.blog > .body > .search > .search {
  height: 28px;
}
.blog > .body > .search > .close {
  height: 18px;
}
.blog > .body > .search > input {
  background: rgba(0, 0, 0, 0);
  border: 0;
  outline: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  word-wrap: break-word;
  color: #fff;
}
.blog > .body > .search > input::placeholder {
  color: #fff;
  font-style: italic;
}
.blog > .body > button {
  padding: 12px 24px;
  color: black;
  font-size: 30px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  background: #ffecec;
  outline: 0;
  border: 0;
  width: 350px;
  border-radius: 45px;
  cursor: pointer;
  margin-top: 54px;
}

@media only screen and (max-width: 1000px) {
  .blog > .body {
    padding: 92px 24px 0;
    align-items: center;
  }
  .blog > .body > .top {
    text-align: center;
    font-size: 36px;
  }
}
