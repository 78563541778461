.footer {
  display: flex;
  flex-direction: column;
  background: linear-gradient(180deg, #021e14 0%, rgba(0, 0, 0, 0) 100%);
  gap: 37px;
  padding: 58px 0px 15px;
  border-top: solid 1.5px white;
}

/* top */
.footer > .top {
  display: flex;
  margin: 0px 0px 38px 0;
  justify-content: center;
  gap: 50px;
}

/* left section */
.footer > .top > .leftSection {
  display: flex;
  flex-direction: column;
}
.footer > .top > .leftSection > img {
  height: 46px;
  width: 55px;
  margin-bottom: 15px;
}
.footer > .top > .leftSection > .desc {
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  word-wrap: break-word;
  margin-bottom: 25px;
  width: 209px;
  margin-bottom: 25px;
}
.footer > .top > .leftSection > .socials {
  display: flex;
  gap: 12px;
}
.footer > .top > .leftSection > .socials > .icons > img {
  height: 25px;
}

/* links */
.footer > .top > .links {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.footer > .top > .links > .header,
.footer > .top > .rightSection > .header {
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  word-wrap: break-word;
}
.footer > .top > .links > .linkslist {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.footer > .top > .links > .linkslist > .link {
  color: #ffffff8c;
  font-size: 12px;
  font-weight: 500;
  word-wrap: break-word;
  text-decoration: none;
  transition: color 0.5s;
}
.footer > .top > .links > .linkslist > .link:hover {
  color: #fff;
}

/* right section  */
.footer > .top > .rightSection {
  display: flex;
  flex-direction: column;
}
.footer > .top > .rightSection > .input {
  margin-top: 15px;
  display: flex;
  align-items: center;
  background-color: #000000;
  padding: 12px 13px 12px 17px;
}
.footer > .top > .rightSection > .input > input {
  border: 0;
  outline: 0;
  background-color: rgba(0, 0, 0, 0);
  font-size: 12px;
  font-weight: 500;
  word-wrap: break-word;
  width: 200px;
  color: white;
}
.footer > .top > .rightSection > .input > img {
  height: 20px;
}
.footer > .top > .rightSection > .status {
  height: 40px;
  font-size: 14px;
  font-weight: 700;
  display: flex;
  text-align: center;
  width: 190px;
  align-self: center;
}
/* bottom */
.footer > .bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.footer > .bottom > .brandname {
  color: #2ed148;
  font-size: 16px;
  font-weight: 700;
  word-wrap: break-word;
}
.footer > .bottom > img {
  height: 20px;
}

.mobileFooter {
  display: flex;
  flex-direction: column;
}
.mobileFooter > .top {
  display: flex;
  align-items: center;
  align-self: center;
  gap: 5px;
  margin-bottom: 20px;
}
.mobileFooter > .top > img {
  height: 42.22px;
  width: 39.38px;
}
.mobileFooter > .top > div {
  font-size: 27px;
  font-weight: 600;
  color: #fff;
}
.mobileFooter > .links {
  margin: 0px 0 32px;
  display: flex;
  justify-content: space-evenly;
}
.mobileFooter > .links > a > img {
  width: 50px;
  height: 50px;
}
.mobileFooter > .bottom {
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
}
.mobileFooter > .version {
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.5);
  text-decoration: underline;
  margin-bottom: 22px;
  margin-top: 3px;
}
@media only screen and (max-width: 325px) {
  .mobileFooter > .links > a > img {
    width: 40px;
    height: 40px;
  }
}
@media only screen and (max-width: 1000px) {
  .footer > .top {
    margin: 0px 0px 20px 0;
    gap: 40px;
  }
  .footer > .top > .leftSection > .desc {
    font-size: 10px;
    width: 155px;
  }
  .footer > .top > .leftSection > .socials {
    gap: 8px;
  }
  .footer > .top > .leftSection > .socials > .icons > img {
    height: 18px;
  }

  .footer > .top > .links > .header,
  .footer > .top > .rightSection > .header {
    font-size: 12px;
  }
  .footer > .top > .links > .linkslist {
    gap: 15px;
  }
  .footer > .top > .links > .linkslist > .link {
    font-size: 10px;
  }
  .footer > .top > .rightSection > .input {
    margin-top: 15px;
    display: flex;
    align-items: center;
    background-color: #000000;
    padding: 8px 10px 8px 12px;
  }
  .footer > .top > .rightSection > .input > input {
    border: 0;
    outline: 0;
    background-color: rgba(0, 0, 0, 0);
    font-size: 10px;
    font-weight: 500;
    word-wrap: break-word;
    width: 150px;
    color: white;
  }
  .footer > .top > .rightSection > .input > img {
    height: 13.5px;
  }
}
