.contact {
  background-size: cover;
  padding: 0 100px 230px 0;
  display: flex;
  justify-content: space-between;
  width: 100vw;
}
.contact > img {
  width: 600px;
  height: 600px;
}
.contact > .container {
  margin-top: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 47px;
}
.contact > .container > .top {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.contact > .container > .top > .header {
  color: #fff;
  font-size: 48px;
  font-weight: 700;
  word-wrap: break-word;
}
.contact > .container > .top > .desc {
  color: rgba(204, 204, 204, 0.75);
  font-size: 25px;
  font-weight: 500;
  line-height: 24px;
  word-wrap: break-word;
}
.contact > .container > .box {
  display: flex;
  flex-direction: column;
  box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  overflow: hidden;
  border: 3.2px rgba(46, 209, 72, 0.45) solid;
  padding: 0 66px 20px;
  width: 720px;
}
.contact > .container > .box > .status {
  height: 40px;
  font-size: 18px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contact > .container > .box > label {
  color: #ffffff91;
  font-size: 18.5px;
  font-weight: 500;
  word-wrap: break-word;
  margin-top: 15px;
  margin-bottom: 7px;
}
.contact > .container > .box > input {
  border-radius: 8px;
  border: 2.5px rgba(46, 209, 72, 0.35) solid;
  font-size: 28px;
  padding: 5px 10px;
  outline: 0;
  background: rgba(0, 0, 0, 0);
  color: white;
}
.contact > .container > .box > textarea {
  border-radius: 8px;
  border: 2.5px rgba(46, 209, 72, 0.35) solid;
  font-size: 20px;
  padding: 5px 10px;
  outline: 0;
  resize: vertical;
  min-height: 215px;
  color: white;
  background: rgba(0, 0, 0, 0);
}
.contact > .container > .box > .bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 38px;
}
.contact > .container > .box > .bottom > .submitA {
  padding: 12px 64px;
  border-radius: 8px;
  border: 2.5px solid #2ed148;
  background: rgba(245, 245, 245, 0.07);
  color: white;
  font-size: 19.5px;
  font-weight: 700;
  word-wrap: break-word;
  cursor: pointer;
}
.contact > .container > .box > .bottom > .submitP {
  padding: 12px 64px;
  border-radius: 8px;
  border: 2.5px solid rgba(0, 0, 0, 0.25);
  background: rgba(245, 245, 245, 0.07);
  color: rgba(255, 255, 255, 0.5);
  font-size: 19.5px;
  font-weight: 700;
  word-wrap: break-word;
  cursor: pointer;
}
.contact > .container > .box > .bottom > .discord {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  font-size: 18.5px;
  font-weight: 500;
}

.contact > .container > .box > .bottom > .discord > .link > img {
  height: 44px;
}

@media only screen and (max-width: 1350px) {
  .contact {
    background-size: cover;
    padding: 0 0 78px 0;
    justify-content: center;
    width: 100vw;
  }
  .contact > img {
    display: none;
  }
}
@media only screen and (max-width: 750px) {
  .contact > .container {
    margin-top: 40px;
  }
  .contact > .container > .top {
    display: none;
  }
  .contact > .container > .box {
    padding: 0 15px 0px;
    width: 95vw;
  }
  .contact > .container > .box > label {
    margin-bottom: 19px;
  }
  .contact > .container > .box > .bottom {
    justify-content: center;
    margin: 38px 0;
  }
  .contact > .container > .box > .bottom > .discord {
    display: none;
  }
}
